import React from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import { Link } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import PageIntro from "../../components/PageIntro";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ContactSection from "../../components/contactSection"
import { GatsbyImage } from "gatsby-plugin-image"
import ImageDisplay from "../../components/ImageDisplay"

import AppearOnScroll from "../../components/AppearOnScroll"

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }
  
function Service({ service, images, id }){
  
      let featuredImage
  
      images.forEach( item => {
              if(item.node.name === id) {
                  featuredImage = item.node.childImageSharp.gatsbyImageData
              }
          }
      )
  
      return (
      <Link to="/contact" className="hover:cursor-pointer">
          <div className="flex flex-wrap items-center justify-between my-12"
              style={{
                  flexDirection: service.imageRight ? "row-reverse" : "row"}}
                  >
              <div className="mb-24 md:w-1/2 md:mb-0 sm:px-4 md:px-6 lg:px-16 xl:px-24">
                  <GatsbyImage image={ featuredImage } alt="Image for Design & Development" />
              </div>
              <div className="flex flex-col w-full md:w-1/2 sm:px-4 lg:px-16 xl:px-24">            
                  <h2 className="text-sm mb-4 uppercase tracking-wider">
                      { service.category }
                  </h2>
                  <h3 className="text-4xl mb-8 font-bold">
                      { service.heading }
                  </h3>

                  <p className="text-lg text-gray-700 mb-6">
                      { service.text }
                  </p>
                  <div className="flex space-between">
                      <Link to="" className="text-lg text-blue-500  mb-6 hover:underline">
                          { service.cta }<FontAwesomeIcon icon={ faChevronRight } className="ml-2 h-4"/>
                      </Link>
                  </div>
                  <div className="flex flex-wrap mb-4 text-gray-700">         
                        { service.keywords.map( item =>
                            <div className="pb-2 w-1/2"><FontAwesomeIcon icon={ faCheck } className="mr-3"/>{item}</div>  
                        )}
                  </div>
              </div>
          </div>
      </Link>
    )
  }
  
  
  
function ServicesDisplay({ data }) {
  
      const images = data.allFile.edges

      // const development = [
      //   {
      //     category: "New store design and development",
      //     heading: "Bring your vision to Shopify.",
      //     id: "1",
      //     text: "Join the over 1.7 million businesses that trust Shopify. Smoothly. Quickly. Easily.",
      //     extraText: "",
      //     keywords: [
      //         "E-commerce",
      //         "New Shopify store",
      //         "Built-in CMS",
      //         "App integration",
      //         "Responsive design",
      //         "Currency Selector",
      //         "Language Selectors",
      //         "Shopify Partners",
      //         "Premium themes", 
      //         "Bespoke design"
      //     ],
      //     link: "/services",
      //     cta: "Start building",
      //   },
      //   {
      //     category: "Migration",
      //     heading: "Switching to Shopify? We got you.",
      //     id: "2",
      //     text: "Moving to Shopify has never been easier—no matter what platform you are currently using.",
      //     extraText: "",
      //     keywords: [
      //         "WooCommerce",
      //         "Magento",
      //         "Wix",
      //         "Squarespace",
      //         "Amazon",
      //         "Etsy",
      //         "BigCommerce",
      //         "Volusion",
      //         "BigCartel",
      //         "WordPress",
      //         "... and more."
      //     ],
      //     link: "/services",
      //     cta: "Switch to Shopify",
      //     imageRight: true,
      //   },
      //   {
      //     category: "Theme Customization",
      //     heading: "See your unique vision come to life.",
      //     id: "3",
      //     text: "Stand out and drive sales with bespoke, personalized features that your customers will love.",
      //     extraText: "",
      //     keywords: [
      //         "Redesigns",
      //         "Bug fixes",
      //         "Tailored digital experience",
      //         "Theme customization",
      //         "Custom features",
      //         "Custom CMS",
      //         "Custom Shopify themes",
      //         "Dynamic free-shipping bar",
      //         "Shopify Partners"
      //     ],
      //     link: "/services",
      //     cta: "Improve your website"
      //   },
      //   {
      //     category: "App Integration",
      //     heading: "Hello, automation. Hello, more free time.",
      //     id: "development",
      //     text: "Give yourself more time to focus on your customers with the 6,000+ apps and solutions Shopify has to offer.",
      //     extraText: "",
      //     keywords: [
      //         "Facebook channel",
      //         "Google channel",
      //         "Point of Sale",
      //         "Shopify email",
      //         "Buy Button channel",
      //         "Oberlo",
      //         "Product Reviews",
      //         "Pinterest",
      //         "Klaviyo",
      //         "Mailchimp",
      //         "Order Printer",
      //         "Judge.me",
      //         "Loox",
      //         "Instafeed",
      //         "Free Shipping Bar",
      //         "Matrixify",
      //         "Currency Selector",
      //         "Language Selectors",
      //     ],
      //     link: "/services",
      //     cta: "Integrate your favorite apps",
      //     imageRight: true,
      //   }
      // ];

      // const optimization = [
      //   {
      //     category: "Conversion-Rate Optimization",
      //     heading: "Drive more sales through data.",
      //     id: "optimization",
      //     text: "Get to know who your customers are, how they behave, and what drives them, so you can sell better, smarter, more.",
      //     extraText: "",
      //     keywords: [
      //         "Google Analytics",
      //         "Facebook Pixels",
      //         "Google Optimize",
      //         "HotJar",
      //         "VWO",
      //         "Subscriptions",
      //         "Bulk selling",
      //         "Market research",
      //         "... and more."
      //     ],
      //     link: "/services",
      //     cta: "Optimize your store"
      //   },
      //   {
      //     category: "Speed optimization",
      //     heading: "Your Shopify website just got faster.",
      //     id: "optimization",
      //     text: "Retain more visitors and reduce your website’s bounce rate by delivering faster page loading times.",
      //     extraText: "",
      //     keywords: [
      //         "Pagespeed Insights",
      //         "Faster loading time",
      //         "Lower visitor bounce rate",
      //         "Lighthouse performance score",
      //         "Image optimization"
      //     ],
      //     link: "/services",
      //     imageRight: true,
      //     cta: "Streamline your website"
      //   },
      //   {
      //     category: "Search Engine Optimization",
      //     heading: "Grow your organic traffic.",
      //     id: "optimization",
      //     text: "Increase your traffic organically and rank higher on search engines by shaping your website’s content to your potential customer.",
      //     extraText: "",
      //     keywords: [
      //         "Google Keywords",
      //         "SEO-friendly meta data",
      //         "SEO keywords",
      //         "Content relevance",
      //         "Backlinks", 
      //         "Link popularity"
      //     ],
      //     link: "/services",
      //     cta: "Optimize your store"
      //   }
      // ];

      const marketing = [
        {
            category: "Branding",
            heading: "Shape your brand.",
            id: "a3",
            text: "Inspire loyalty with a unique brand that resonates with your vision and customers.",
            extraText: "",
            keywords: [
                "Brand identity", 
                "Rebrands", 
                "Packaging", 
                "Logo design", 
                "Style guide"
            ],
            link: "/services",
            cta: "Design your vision",
        },
      //   {
      //     category: "Marketing",
      //     heading: "More reach. More sales.",
      //     id: "3",
      //     text: "Drive more customers to your store and engage a world-wide audience with thoughtful, data-driven campaigns.",
      //     extraText: "",
      //     keywords: [
      //         "Klaviyo",
      //         "mailChimp",
      //         "Copy writing",
      //         "Google Ads",
      //         "Pinterest Ads",
      //         "Instagram Ads",
      //         "Facebook Ads",
      //         "Email Marketing",
      //         "Click-through-rate optimization",
      //         "Social Media campaigns"
      //     ],
      //     link: "/services",
      //     cta: "Grow your reach",
      //     imageRight: true,
      // },
    ];
  
      return (
          <>
            <ImageDisplay 
                images={ images } 
                selection={["2"]}
              />
              {/* {
                development.map( service => 
                  <Service service={ service } images={ images } id={ service.id} />
                  )
              }
              <SectionIntroVersion2 
                title="Optimization"
                headline="Sell smarter. Sell more."
                text="Engage your customers the right way with speed-optimized, data-driven, personalized experiences."
              />
              {
                optimization.map( service => 
                  <Service service={ service } images={ images } id={ service.id} />
                  )
              }
              <SectionIntroVersion2 
                title="Branding & Marketing"
                headline="Hyper-scale your reach."
                text="Amplify your brand’s voice and drive more customers to your website by telling your story the right way."
              /> */}


                {/* {
                  marketing.map( service => 
                    <Service service={ service } images={ images } id={ service.id} />
                    )
                  } */}
                <ImageDisplay 
                  images={ images } 
                  selection={["3"]}
                  />
              <ImageDisplay 
                images={ images } 
                selection={["6"]}
                constrained = { true }
              />
              <ImageDisplay 
                images={ images } 
                selection={["1a"]}
              />
              <ImageDisplay 
                images={ images } 
                selection={["5"]}
                constrained = { true }
              />

          </>
      )
  }


// function ImageDisplay({ images, selection, full }){
//   let featuredImages = [];

//   let selectedImagesArr = selection;

//   for (let i = 0; i < selectedImagesArr.length; i++){
//     images.forEach( item => {
//             if(item.node.name === selectedImagesArr[i]) {
//                 featuredImages.push( item.node.childImageSharp.gatsbyImageData )
//             }
//         }
//     )
//   }

//   return (
//     <div className="">
//       {
//         featuredImages.map( (image, idx) => <GatsbyImage image={ image } className={`${ full ? "" : " mx-auto block w-4/5 " } mb-4 `} alt="Image for Design & Development" id={ idx } /> )
//       }
//     </div>
//   )
  
  

// }

const ServicesPage = ({ data }) => {  
    return (
 
      <Layout navBarType="white">
          <Seo title="Services" />
          <PageIntro 
            title="White & Colors"
            headline="Where urban meets sexy."
            />  
            <ServicesDisplay data={ data }/>
            <ContactSection />
      </Layout>

)}




export default ServicesPage



export const query = graphql`
query WhitePageQuery {
    allFile(
        filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "white&colors"}}
        ) {
        edges {
            node {
            childImageSharp {
                gatsbyImageData
            }
            name
            }
        }
    }
}
`